import React from "react"
import Layout from "../components/layout"

import { graphql } from "gatsby"

export default function Template({
  data,
}) {
  const { markdownRemark } = data
  const { html } = markdownRemark
  return (
   <Layout title={markdownRemark.frontmatter.title}>
    <div className="blog-post-container">
      <div className="blog-post">
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
    </div>
   </Layout>
  )
}
export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
	title
      }
    }
  }
`
